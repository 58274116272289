import React from 'react'
import SectionOneQS from '../../Components/SectionOneQS/SectionOneQS';

export default function QualityStandards() {
  return (
    <>
      <SectionOneQS />
    </>
  )
}
