import React from 'react';
import "./HomeSectionOne.css";
import Header from '../Header/Header';

export default function HomeSectionOne() {

    const scrollForm=()=>{
        document.querySelector('.sectionThree__right').scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

  return (
    <div className='sectionOne'>
        <div className ='sectionOne__left'>
            <Header path='images/logo.svg' />
            <div className='textbox'>
                <h1>PharmScale</h1>
                <div className='textbox__grid'>
                    <div className='hr'>
                        <hr style={{width: '100%', height: '4px', backgroundColor: '#90BE21', border: 'none'}} />
                    </div>
                    <p>
                        Next level of nutra manufacturing <br/><br/>
                        Create your brand with premium nutra manufacturing service <br/><br/>
                        Enrich your product lineup and boost your brand with our nutra manufacturing expertise.<br/><br/>
                        Contact us and find out what’s the best option for your brand <br/><br/>
                        <button onClick={scrollForm}>CONTACT US</button>
                    </p>
                </div>
            </div>
        </div>
        <div className ='sectionOne__right'>
            <img id='naturaPic' src='/images/natura.png' />
            <img id='secOnePic' src='/images/homeSec1.png' />
        </div>
    </div>
  )
}
