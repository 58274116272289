import React from 'react';
import "./SectionOneONS.css";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

export default function SectionOneONS() {
  return (
    <div className='sectionOneONS'>
        <div className = 'sectionOneONS__left'>
            <Header path='/images/logo1.svg' />
            <div className='sectionOneONS__textbox'>
                <h1>Our nutra services</h1>
                <div className='sectionOneONS__textbox__grid'>
                    <div className='hr'>
                        <hr style={{width: '100%', height: '4px', backgroundColor: '#90BE21', border: 'none'}} />
                    </div>
                    <p>
                        Our experienced pharmacists can develop private labeled products specifically tailored to your needs, in addition to white-labeled products.<br/><br/>
                        We know that manufacturing is an essential piece of the puzzle but not the only one. <br/><br/>
                        To help you with branding, we also offer packaging services and the service of professional designers and production teams.<br/><br/>
                        This way, you get a professional package design and product images with your brand label on them, perfect for online retail and product catalogs.<br/><br/>
                        <button>CONTACT US</button>
                    </p>
                </div>
            </div>
        </div>
        <div className = 'sectionOneONS__right'>

        </div>
        <Footer />
    </div>
  )
}
