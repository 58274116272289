import React from 'react';
import "./HomeSectionTwo.css";

export default function HomeSectionTwo() {

    const scrollForm=()=>{
        document.querySelector('.sectionThree__right').scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

  return (
    <div className='sectionTwo'>
        <div className='sectionTwo__textbox'>
            <p id='green'>Pharm Scale’s experienced pharmacists are also equipped with knowledge and tools to design your premium products from scratch based on your input.</p>
            <br/>
            <p id='item'><img src='/images/chech2.png' /><p>Our facility is ISO and GMP certified, which guarantees you will have high-quality products throughout the batches.</p></p>
            <br/>
            <p id='item'><img src='/images/chech2.png' /><p>We are also your one-stop solution offering services of professional designers and high-quality printing and packaging so you can fully dedicate yourself to building brand strategy.</p></p>
            <br/>
            <p>Contact us and find out what’s the best option for your brand</p>
        </div>
        <div className='sectionTwo__textbox2'>
                <h1>Why choose PharmScale?</h1>
                <div className='sectionTwo__textbox2__grid'>
                    <div className='hr'>
                        <hr style={{width: '100%', height: '4px', backgroundColor: '#90BE21', border: 'none'}} />
                    </div>
                    <p>
                        Our team's impeccable knowledge of the ingredients and technology is an integral part of our winning formula that will never change. <br/><br/>
                        PharmScale’s factory is located in Srebrenik, Bosnia, and Herzegovina. We are not brokers, but true nutra manufacturers. <br/><br/>
                        We produce dozens of products in-house, which allows us to offer these high-quality items at an affordable price for your white-label products.<br/><br/>
                        <button onClick={scrollForm}>CONTACT US</button>
                    </p>
                </div>
        </div>
    </div>
  )
}
