import {React, useState} from 'react';
import { Link } from 'react-router-dom';
import "./Header.css";

export default function Navbar(props) {

  const [menuOpen, setMenuOpen] = useState(false);

  const menuHandler = (event) => {
    setMenuOpen(m => !m);
  }
  
  return (
    <>
      <div className='header'>
          <div className ='header__head'>
            <img src={props.path}/>
            <i onClick={()=>menuHandler()} id='open-btn' className="uil uil-bars"></i>
          </div>
          <ul id='nav-ul'>
              <li><Link id='nav-item' to='/'>Home</Link></li>
              <li><Link id='nav-item' to='/qualitystandards'>Quality standards</Link></li>
              <li><Link id='nav-item' to='/ournutraservices'>Our nutra services</Link></li>
          </ul>
      </div>
      
      <div style={{display: menuOpen ? "flex" : "none"}} className='header-mobile'>
        <div className='header-mobile__head'>
          <img src='images/logo.svg'/>
          <i onClick={()=>menuHandler()} id='close-btn' className="uil uil-multiply"></i>
        </div>
            <ul id='nav-ul-mobile'>
                <li><Link id='nav-item' to='/'>Home</Link></li>
                <li><Link id='nav-item' to='/qualitystandards'>Quality standards</Link></li>
                <li><Link id='nav-item' to='/ournutraservices'>Our nutra services</Link></li>
            </ul>
        <div className='header-mobile__socials'>
          <a href='https://www.facebook.com/pharmscale/'><img id='icon' src='/images/fb.png' /></a>
          <a href='https://www.linkedin.com/company/obscale'><img id='icon' src='/images/linkedin.png' /></a>
          <a href='https://www.instagram.com/pharmscale/'><img id='icon' src='/images/insta.png' /></a>
        </div>
      </div>
    </>
  )
}