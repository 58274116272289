import React from 'react';
import "./Footer.css";
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className='footer'>
        <div className='footer__copyright'>
            <img src='/images/logo.svg' />
            <p>© 2022 PharmScale, Obscale d.o.o.</p>
        </div>
        <div className='footer__none'>
            
        </div>
        <div className='footer__none'>

        </div>
        <div className='footer__links'>
            <ul id='nav-ul'>
                <li><Link id='nav-item' to='/'>Home</Link></li>
                <li><Link id='nav-item' to='/qualitystandards'>Quality standards</Link></li>
                <li><Link id='nav-item' to='/ournutraservices'>Our nutra services</Link></li>
            </ul>
        </div>
        <div className='footer__info'>
            <span><img src='/images/mail.png' />info@pharmscale.com</span>
            <span><img src='/images/loc.png' />Ćehaje bb, 75350 Srebrenik</span>
            <span><img src='/images/loc.png' />Hours of operation: <br/> Monday - Friday 8am - 4:30pm</span>
        </div>
        <div className='footer__socials'>
            <a href='https://www.facebook.com/pharmscale/'><img src='/images/fb.png' /></a>
            <a href='https://www.linkedin.com/company/obscale'><img src='/images/linkedin.png' /></a>
            <a href='https://www.instagram.com/pharmscale/'><img src='/images/insta.png' /></a>
        </div>
        <p id='copy__mobile'>© 2022 PharmScale, Obscale d.o.o.</p>
    </div>
  )
}
