import React from 'react';
import "./SectionTwoONS.css";

export default function SectionTwoONS() {
  return (
    <div className='sectionTwoONS'>
        <div className='sectionTwoONS__fbox'>
            <div className='hr'>
                <hr style={{width: '100%', height: '4px', backgroundColor: '#90BE21', border: 'none'}} />
            </div>
            <p>
                If you're looking to make a breakthrough in the European Union markets, we also offer counseling services to help accommodate legislation that specific countries have. <br/><br/>
                If you're unsure which road to take, contact us, and we'll gladly assist you by giving you advice that will help you make your decision.<br/><br/>
                Find out what's the best option for you.
            </p>
        </div>
    </div>
  )
}
