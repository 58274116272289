import React from 'react';
import "./SectionOneQS.css";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

export default function SectionOneQS() {
  return (
    <div className='sectionOneQS'>
        <div className='sectionOneQS__left'>
            <Header path='/images/logo1.svg' />
            <div className='sectionOneQS__textbox'>
                <h1>Quality Standards</h1>
                <div className='sectionOneQS__textbox__grid'>
                    <div className='hr'>
                        <hr style={{width: '100%', height: '4px', backgroundColor: '#90BE21', border: 'none'}} />
                    </div>
                    <p>
                        All products produced by Pharm Scale are subject to our extensive quality control system.<br/><br/>
                        Our new factory is equipped for manufacturing complete turnkey products, from soft gelatin dosage forms and two-piece hardshell to our multi pill sachets, bottling, and blister packaging options.<br/><br/>
                        But it all starts with quality ingredients.<br/><br/>
                        <button>CONTACT US</button>
                    </p>
                </div>
            </div>
        </div>
        <div className='sectionOneQS__right'>
            <div className='sectionOneQS__textbox2'>
                <p id='green'>Pharm Scale’s experienced pharmacists are also equipped with knowledge and tools to design your premium products from scratch based on your input.</p>
                <br/><br/>
                <p id='item'><img src='/images/chech2.png' /><p>Our facility is ISO and GMP certified, which guarantees you will have high-quality products throughout the batches.</p></p>
                <br/><br/>
                <p id='item'><img src='/images/chech2.png' /><p>We are also your one-stop solution offering services of professional designers and high-quality printing and packaging so you can fully dedicate yourself to building brand strategy.</p></p>
                <br/><br/>
                <p>Contact us and find out what’s the best option for your brand</p>
            </div>
        </div>
        <Footer />
    </div>
  )
}