import { Route, Routes} from "react-router-dom";
import Home from "./Pages/Home/Home";
import QualityStandards from "./Pages/QualityStandards/QualityStandards";
import OurNutraServices from "./Pages/OurNutraServices/OurNutraServices";
import "./App.css";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/qualitystandards" element={<QualityStandards />} />
            <Route path="/ournutraservices" element={<OurNutraServices />} />
        </Routes>
    </div>
  );
}

export default App;
