import React from 'react';
import "./HomeSectionThree.css";

export default function HomeSectionThree() {

  const changeHandler = () => {
        const inputs = document.querySelectorAll('input');
        const textarea = document.querySelector('#message');

        inputs.forEach(field => {
            if(field.value != ''){
                field.style.border = '1px solid #90BE21';
            }
        });

        if(textarea.value != ''){
            textarea.style.border = '1px solid #90BE21';
        }
  }

  return (
    <div className='sectionThree'>
        <div className='sectionThree__grid'>
            <div className='sectionThree__left'>
                <div className='sectionThree__textbox'>
                    <h1>Contact us</h1>
                    <div className='sectionThree__textbox__grid'>
                        <div className='hr'>
                            <hr style={{width: '100%', height: '4px', backgroundColor: '#90BE21', border: 'none'}} />
                        </div>
                        <p>
                            For all inquiries, please get in touch.<br/><br/>
                            Order a free meeting where you will get the following information:<br/><br/>
                            <p id='item'><img src='/images/chech2.png' /><p>Ingredients, price, and time of manufacturing</p></p>
                            <br/>
                            <p id='item'><img src='/images/chech2.png' /><p>Design and packaging options</p></p>
                            <br/>
                            <p id='item'><img src='/images/chech2.png' /><p>Delivery options</p></p>
                            <br/>
                            Please feel free to contact us and we will get back to you within 12 hours.<br/><br/>
                        </p>
                    </div>
                </div>
            </div>
            <div className='sectionThree__right'>
                <img id='naturaPic2' src='/images/natura2.png' />
                <form>
                    <div className='sectionThree__right--grid'>
                        <input onChange={changeHandler} type='text' placeholder='Name / Company name*' />
                        <input onChange={changeHandler} type='text' placeholder='Location' />
                        <input onChange={changeHandler} type='text' placeholder='Phone number*' />
                        <input onChange={changeHandler} type='text' placeholder='Email adress*' />
                    </div>
                    <textarea onChange={changeHandler} id='message' rows = '60' cols = '60' placeholder='Message*'></textarea>
                    <input type='submit' value='CONTACT US' />
                </form>
            </div>
        </div>
    </div>
  )
}
