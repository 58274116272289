import React from 'react';
import SectionOneONS from '../../Components/SectionOneONS/SectionOneONS';
import SectionTwoONS from '../../Components/SectionTwoONS/SectionTwoONS';

export default function OurNutraServices() {
  return (
    <>
      <SectionOneONS />
      <SectionTwoONS />
    </>
  )
}
