import React from 'react';
import "./Home.css";
import HomeSectionOne from "../../Components/HomeSectionOne/HomeSectionOne";
import HomeSectionTwo from '../../Components/HomeSectionTwo/HomeSectionTwo';
import HomeSectionThree from '../../Components/HomeSectionThree/HomeSectionThree';
import Footer from '../../Components/Footer/Footer';

export default function Home() {
  return (
    <>
      <HomeSectionOne />
      <HomeSectionTwo />
      <HomeSectionThree />
      <Footer />
    </>
  )
}
